import { useState, useEffect } from "react";

import { Table } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import { Button, Modal } from "antd";

import TableButtons from "../../../components/TableButtons";

import REQUESTS from "../../../api/requests";

import DNSDrawer from "./DNSDrawer";

export default function DNS() {
    const [data, setData] = useState([]);

    const [total, setTotal] = useState();

    const [limit, setLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [current, setCurrent] = useState(null);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "DNS",
            dataIndex: "dns",
            key: "dns",
            align: "center",
        },
        {
            title: "Activation count",
            dataIndex: "activation_count",
            key: "activation_count",
            align: "center",
        },
        {
            title: "",
            dataIndex: "action",
            width: "60px",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    buttons={[
                        { key: "edit", text: "edit", icon: <EditOutlined /> },
                        {
                            key: "delete",
                            text: "delete",
                            icon: <DeleteOutlined />,
                        },
                    ]}
                />
            ),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const deleteDns = (id) => {
        REQUESTS.FREE_DNS.DELETE({ id }, () => {
            getData()
        })
    }

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "delete":
                Modal.confirm({
                    title: "Are you sure you want to delete?",
                    okText: "DELETE",
                    okButtonProps: {
                        danger: true,
                        type: "primary",
                        style: {
                            backgroundColor: "#ff4d4f",
                        }
                    },
                    onOk() {
                        deleteDns(item.id);
                    },
                });

                break;

            case "edit":

                setOpen(true);

                setCurrent(item);

                break;

            default:
                break;
        }
    };

    const getData = () => {

        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
        }

        function callback(data) {
            setLoading(false);

            setTotal(data.count);

            setLimit(data.limit);

            setData(data.rows);
        }

        function errorCallback(error) {
            setLoading(false);
        }

        REQUESTS.FREE_DNS.GET(query,callback, errorCallback);
    }

    useEffect(() => {
        let timout = setTimeout(() => {
            getData();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [currentPage, limit]);

    return (
        <div>
            <div className="settings-free-dns">
                <h3>DNS</h3>

                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />

            <DNSDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    setCurrent(null);
                }}
                getData={getData}
                current={current}
            />
        </div>
    )
}
