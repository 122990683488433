import { useEffect, useState } from "react";

import { Table } from "antd";

import getDate from "../../../components/getDate";

import logs from "../logs";

export default function RestoreLogs({ userInfo }) {
    const [data, setData] = useState([]);

    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            },
          },
        {
            title: "From device",
            fixed: "left",
            align: "center",
            render: (record) => {
                return <div>
                    <div> {record.from_model}</div>
                    <div>
                        {record.from_device_mac}
                    </div>
                </div>
            }
        },
        {
            title: "To device",
            fixed: "left",
            align: "center",
            render: (record) => {
                return <div>
                    <div> {record.to_model}</div>
                    <div>
                        {record.to_device_mac}
                    </div>
                </div>
            }
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            fixed: "left",
            align: "center",
            render: (record) => {
                return getDate(record)
            }
        },
    ];

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    useEffect(() => {
        if (userInfo) {
            logs(userInfo.mac).then((res) => setData(res))
        }
    }, [userInfo])

    return (
        <div>
            <p className="table-title">Restore Logs</p>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                scroll={{ x: "max-content" }}
                size="small"
                pagination={{ ...pagination,}}
                onChange={handleTableChange}
            />
        </div>
    )
}
