import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Tabs } from "antd";

import Server from "../server";
import Languages from "../languages";
import SMTPConfig from "./components/SMTPconfig";
import ApplicationSettigs from "../application/components/AplicationSettings";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../features/widgets/selector";
import { useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import PaymentSettings from "./components/PaymentSettings";
import WebTranslation from "./components/web-translation/WebTranslation";
import ResellerTranslation from "./components/reseller-translations/ResellerTranslation"
import TEXT from "../../config/text";
import ICONS from "../../config/icons";

import "./index.scss";
import Branding from "./components/Branding";
import GoogleIMA from "./components/GoogleIMA";
import GoogleReCaptcha from "./components/GoogleReCaptcha";
import XtreamUI from "./components/XtreamUI";
import GEORestriction from "./components/GEORestriction";
import BackupPlaylist from "./components/BackupPlaylist";
import DNS from "./components/DNS";
import SubtitlePage from "./components/SubtitlePage";

const { TabPane } = Tabs;

const Settings = () => {

  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const [searchParams, setSearchParams] = useSearchParams();

  const [actiaveKey, setActiveKey] = useState(location.search.split("=")[1]);

  useEffect(() => {
    if (actiaveKey) {
      setSearchParams({ 'page': actiaveKey })
    }
  }, [actiaveKey])

  useEffect(() => {
    const changeWindowWidth = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", changeWindowWidth);

    return () => window.removeEventListener("resize", changeWindowWidth);
  }, []);

  return (
    <div>
      <Tabs
        tabPosition={windowWidth > 539 ? "left" : "top"}

        activeKey={actiaveKey}
        onChange={setActiveKey}
        className="settings-page-tabs-component"
      >
        <TabPane tab={`SMTP`} key={"SMTP"}>
          <SMTPConfig />
        </TabPane>
        <TabPane
          tab={translation["Application"] || TEXT["Application"]}
          key={"applicationSettigs"}
        >
          <ApplicationSettigs />
        </TabPane>
        <TabPane
          tab={translation["Branding"] || TEXT["Branding"]}
          key={"branding"}
        >
          <Branding />
        </TabPane>
        <TabPane
          tab={translation["Payment settings"] || TEXT["Payment settings"]}
          key={"paymentSettings"}
        >
          <PaymentSettings />
        </TabPane>
        {widgets?.server?.enabled === "true" ? (
          <TabPane tab={translation["Server"] || TEXT["Server"]} key={"server"}>
            <Server />
          </TabPane>
        ) : (
          ""
        )}
        <TabPane
          tab={translation["Languages"] || TEXT["Languages"]}
          key={"languages"}
        >
          <Languages />
        </TabPane>

        <TabPane tab={"Web translation"} key={"web-translation"}>
          <WebTranslation />
        </TabPane>

        <TabPane tab={"Reseller translation"} key={"reseller-translation"}>
          <ResellerTranslation />
        </TabPane>

        <TabPane tab={<>Google IMA {ICONS.PRO}</>} key={"google-IMA"}>
          <GoogleIMA />
        </TabPane>

        <TabPane tab={<>Google reCAPTCHA</>} key={"google-reCAPTCHA"}>
          <GoogleReCaptcha />
        </TabPane>

        <TabPane tab={<>Xtream UI {ICONS.PRO}</>} key={"Xtream_rUI"}>
          <XtreamUI />
        </TabPane>

        <TabPane tab={<>GEO Restriction {ICONS.PRO}</>} key={"GEO_Restriction"}>
          <GEORestriction />
        </TabPane>
        <TabPane tab={"Backup playlist"} key={"Backup_playlist"}>
          <BackupPlaylist />
        </TabPane>
        <TabPane tab={"DNS"} key={"DNS"}>
          <DNS />
        </TabPane>
        <TabPane tab={"Subtitles"} key={"subtitles"}>
          <SubtitlePage/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Settings;
