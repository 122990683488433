import REQUESTS from "../../api/requests";

export default async function logs(mac) {
    const getRestoreLogs = async (filter) => {
        return new Promise((resolve, reject) => {
            const query = { filter: JSON.stringify(filter),limit:1000 };

            REQUESTS.DEVICE_LOGS(query, (data) => {
                resolve(data);
            });
        });
    };

    const getRestoreLogsFrom = async () => {
        const filter = { from_device_mac: [mac] };
        return await getRestoreLogs(filter);
    };

    const getRestoreLogsTo = async () => {

        const filter = { to_device_mac: [mac] };
        return await getRestoreLogs(filter);
    };

    try {
        const dataFrom = await getRestoreLogsFrom();
        const dataTo = await getRestoreLogsTo();

        return [...dataFrom.rows, ...dataTo.rows]

    } catch (error) {
        console.error('Error fetching logs:', error);
    }
}
