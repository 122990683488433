import React, { useEffect, useState } from 'react'

import { Drawer, Input, Form, Button } from 'antd'

import REQUESTS from '../../../api/requests';

import ErrorMessage from '../../../components/ErrorMessage';

export default function DNSDrawer({ open, onClose, getData, current }) {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });


    const onFinish = (values) => {
        setMessage({
            type: false,
            text: "",
        })

        const { dns } = values;

        const body = {
            dns
        }

        function callback(data) {
            onClose();

            getData();
        }

        function errorCallback(error) {
            setMessage({
                type: false,
                text: error,
            })
        }

        if (current) {
            body.id = current.id;
            REQUESTS.FREE_DNS.PUT(body, callback, errorCallback)
        } else {
            REQUESTS.FREE_DNS.POST(body, callback, errorCallback)
        }

    };

    useEffect(() => {
        if (current) {
            form.setFieldsValue({
                dns: current.dns
            })
        } else {
            form.resetFields();
        }
    }, [current, open])

    return (
        <Drawer
            open={open}
            onClose={() => {
                onClose()
                form.resetFields();
            }}
            title={current ? "Edit DNS" : "Add DNS"}
        >
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label='DNS'
                    name="dns"
                    rules={[
                        {
                            required: true,
                            message: 'Please input DNS'
                        },
                        { validator:(rule, value, callback) => {
                            // Regular expression for URL validation
                            const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                        
                            if (!value || urlPattern.test(value)) {
                              callback(); // Validation passed
                            } else {
                              callback('Please enter a valid URL'); // Validation failed
                            }
                          } },
                    ]}>
                    <Input />
                </Form.Item>
                
                <ErrorMessage message={message} />

                <Form.Item style={{ textAlign: "center" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
